define("ember-modal-dialog/templates/components/tether-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template(
  /*
    {{#if this.hasOverlay}}
    <EmberWormhole @to={{this.destinationElementId}}>
      <div
        class={{this.overlayClassNamesString}}
        onclick={{action this.onClickOverlay}}
        tabindex='-1'
        data-emd-overlay
      >
      </div>
    </EmberWormhole>
  {{/if}}
  <EmberTether
    @class={{this.containerClassNamesString}}
    @target={{this.tetherTarget}}
    @attachment={{this.attachment}}
    @targetAttachment={{this.targetAttachment}}
    @targetModifier={{this.targetModifier}}
    @classPrefix={{this.tetherClassPrefix}}
    @offset={{this.offset}}
    @targetOffset={{this.targetOffset}}
    @constraints={{this.constraints}}
    ...attributes
  >
    {{yield}}
  </EmberTether>
  */
  {
    "id": "HSb8238W",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[6,[37,1],[[32,0,[\"hasOverlay\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"ember-wormhole\",[],[[\"@to\"],[[32,0,[\"destinationElementId\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[15,0,[32,0,[\"overlayClassNamesString\"]]],[15,\"onclick\",[30,[36,0],[[32,0],[32,0,[\"onClickOverlay\"]]],null]],[14,\"tabindex\",\"-1\"],[14,\"data-emd-overlay\",\"\"],[12],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[8,\"ember-tether\",[[17,1]],[[\"@class\",\"@target\",\"@attachment\",\"@targetAttachment\",\"@targetModifier\",\"@classPrefix\",\"@offset\",\"@targetOffset\",\"@constraints\"],[[32,0,[\"containerClassNamesString\"]],[32,0,[\"tetherTarget\"]],[32,0,[\"attachment\"]],[32,0,[\"targetAttachment\"]],[32,0,[\"targetModifier\"]],[32,0,[\"tetherClassPrefix\"]],[32,0,[\"offset\"]],[32,0,[\"targetOffset\"]],[32,0,[\"constraints\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"if\"]}",
    "meta": {
      "moduleName": "ember-modal-dialog/templates/components/tether-dialog.hbs"
    }
  });
  _exports.default = _default;
});